import {ArchiveOutlined, InboxOutlined, OutboxOutlined} from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import ReplayIcon from '@mui/icons-material/Replay';
import SendIcon from '@mui/icons-material/Send';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import {
    Alert,
    Box,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    LinearProgress,
    Popover,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tabs,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_DE } from 'material-react-table/locales/de';
import React, { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import ContactService from '../api/services/Contact';
import DocumentService from '../api/services/Document';
import RequestService from '../api/services/Request';
import { CircularLoading } from '../components/CircularLoading';
import { CopyAngeboteDialog } from '../components/CopyAngeboteDialog';
import { DownloadFiles } from '../components/DocumentMeta';
import ImportDialog from '../components/ImportDialog';
import { PageLayout } from '../components/PageLayout';

export const REQUEST_STATUS = {
    1: { text: 'E-Mail gesendet', icon: SendIcon, color: 'info' },
    2: { text: 'E-Mail zugestellt', icon: MarkEmailReadIcon, color: 'primary' },
    3: { text: 'Antwort erhalten', icon: MoveToInboxIcon, color: 'success' },
    4: { text: 'E-Mail fehlgeschlagen', icon: SmsFailedIcon, color: 'error' }
};

export const DOCUMENT_STATUS = {
    1: 'Nicht bearbeitet',
    2: 'Positionen zugewiesen',
    3: 'Anfragen versendet',
    4: 'Angebote erhalten',
    5: 'Angebot versendet'
};

const displayDate = (time) => {
    const date = new Date(time);
    return date.toLocaleDateString('de-DE');
};

function ResendDialog({ open, setOpen, contact, requestId, invalidate }) {
    const [errorMessage, setErrorMessage] = useState(null);
    const [name, setName] = useState(contact.name);
    const [email, setEmail] = useState(contact.email);
    const [inTransaction, setInTransaction] = useState(false);

    const handleSubmission = async () => {
        setInTransaction(true);
        try {
            await ContactService.patch(contact.id, { name: name, email: email });
            await RequestService.patch(requestId, contact.id);
        } catch (error) {
            setErrorMessage(error.response.data.message);
        } finally {
            // Close the dialog and invalidate the query
            setInTransaction(false);
            setOpen(false);
            invalidate();
        }
    };

    return (
        <div>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Kontakt bearbeiten</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}
                    </DialogContentText>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="company"
                        label="Company"
                        name="company"
                        autoComplete="off"
                        autoFocus
                        defaultValue={contact.provider.name}
                        disabled={true}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="contact-name"
                        label="Name"
                        name="contact-name"
                        autoComplete="off"
                        autoFocus
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        disabled={inTransaction}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="contact-email"
                        label="E-mail"
                        id="contact-email"
                        autoComplete="off"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        disabled={inTransaction}
                    />
                    {inTransaction ? (
                        <div style={{ display: 'flex' }}>
                            <CircularLoading width="30px" mr="4" /> Neue E-Mail wird versendet...
                        </div>
                    ) : null}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button onClick={handleSubmission} disabled={inTransaction}>
                        Email wiederholen
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

function DownloadPopover({ offerFiles }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <IconButton aria-describedby={id} color="primary" onClick={handleClick}>
                <FileDownloadIcon />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}>
                <DownloadFiles offerFiles={offerFiles} />
            </Popover>
        </>
    );
}

/**
 * @param {Request} request the request to display
 * @param {function} invalidate function to invalidate the offers query
 * @returns the display row of a request
 */
function RequestRow({ request, invalidate }) {
    const [resendDialogOpen, setResendDialogOpen] = useState(false);

    let Icon = REQUEST_STATUS[request.state].icon;
    let color = REQUEST_STATUS[request.state].color;
    let title = REQUEST_STATUS[request.state].text;
    return (
        <TableRow>
            <TableCell>FT-{request.pseudo_id}X</TableCell>
            <TableCell component="th" scope="row">
                {request.contact.name}
            </TableCell>
            <TableCell>{request.contact.email}</TableCell>
            <TableCell>
                {request.answer_received ? new Date(request.answer_received).toLocaleString('de-DE') : '-'}
            </TableCell>
            <TableCell>
                <Tooltip title={title}>
                    <Icon color={color} />
                </Tooltip>
            </TableCell>
            <TableCell>
                {request.state === 4 && (
                    <>
                        <Button
                            variant="contained"
                            size="small"
                            color="info"
                            onClick={() => setResendDialogOpen(true)}
                            startIcon={<ReplayIcon fontSize="small" />}
                            sx={{ marginRight: '5px' }}>
                            Wiederholen
                        </Button>
                        <ResendDialog
                            open={resendDialogOpen}
                            setOpen={setResendDialogOpen}
                            contact={request.contact}
                            requestId={request.id}
                            invalidate={invalidate}
                        />
                    </>
                )}
            </TableCell>
        </TableRow>
    );
}

function RequestView({ requests, invalidate }) {
    if (requests.length === 0) {
        return (
            <div>
                <Typography variant="h6" gutterBottom component="div">
                    Keine Anfragen gestellt
                </Typography>
            </div>
        );
    }

    return (
        <Grid
            container
            sx={{
                borderBottomRightRadius: '10px',
                border: '1px solid #e0e0e0',
                borderColor: 'divider',
                borderBottomLeftRadius: '10px',
                borderTop: 'none',
                p: 2,
                mt: -2,
                boxShadow: '0px 5px 5px 0px rgb(67, 150, 116, 0.1)',
                '& th': {
                    textTransform: 'none !important',
                    background: 'unset'
                },
                '& td': {
                    fontSize: '14px'
                }
            }}>
            <Grid item xs={2}>
                <Typography variant="h6" gutterBottom component="div" sx={{ color: 'neutral.700' }}>
                    Anfragen für dieses Objekt
                </Typography>
            </Grid>
            <Grid item xs={10}>
                <Table size="small" aria-label="purchases">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Lieferant</TableCell>
                            <TableCell>E-Mail</TableCell>
                            <TableCell>Datum Eingang</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Aktionen</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {requests.map((requestRow, i) => (
                            <RequestRow request={requestRow} key={i} invalidate={invalidate} />
                        ))}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    );
}

export default function DocumentTablePage({ typesToDisplay, actionsToDisplay, excludeCols, title, type }) {
    const [columnFilters, setColumnFilters] = useState([]); //[['user', { id: user.id }]]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([{ id: 'created_at', desc: true }]);
    const [errorMessage, setErrorMessage] = useState('');
    const [entityToDelete, setEntityToDelete] = useState(null);
    const [inTransaction, setInTransaction] = useState(false);

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 25
    });

    const [tabValue, setTabValue] = useState(0);

    useEffect(() => {
        setTabValue(type === 'archive' ? 1 : 0);
    }, [type]);

    const groupDocumentData = async () => {
        const response = await DocumentService.getMultiple(
            pagination.pageIndex,
            pagination.pageSize,
            columnFilters,
            globalFilter,
            sorting,
            typesToDisplay
        );
        const documents = response.data.documents.map((e) => ({
            ...e,
            num_requests: e.requests.length,
            num_responses: e.requests.filter((request) => request.answer_received).length
        }));

        return { ...response, data: { ...response.data, documents: documents } };
    };

    const queryClient = useQueryClient();

    const { isLoading, isFetching, isError, error, data } = useQuery(
        ['documents', type, columnFilters, globalFilter, pagination.pageIndex, pagination.pageSize, sorting],
        () => groupDocumentData(),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            staleTime: 10000
        }
    );

    const invalidate = () => {
        queryClient.invalidateQueries(['documents', type]);
    };

    const archiveMutation = useMutation(
        (documentID) => {
            return DocumentService.patch(documentID, { state: 6 });
        },
        {
            onSuccess: () => {
                // TODO: use result of mutation directly
                queryClient.invalidateQueries('documents');
            },
            onError: (error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        setErrorMessage(error.response.status + ': Validation error: could not process');
                    } else {
                        setErrorMessage(error.response.status + ': ' + error.response.data.message);
                    }
                } else if (error.request) {
                    setErrorMessage('Server not reachable');
                    console.log(error.request);
                } else {
                    setErrorMessage('Undefined error, please contact your admin');
                    console.log('Error', error.message);
                }
                setTimeout(function () {
                    setErrorMessage('');
                }, 10000);
            }
        }
    );

    const unarchiveMutation = useMutation(
        ({documentID, last_state}) => {
            if (last_state !== undefined && last_state !== null && last_state > 0) {
                return DocumentService.patch(documentID, { state: last_state });
            } else {
                return Promise.reject('No last state found');
            }
        },
        {
            onSuccess: () => {
                // TODO: use result of mutation directly
                queryClient.invalidateQueries('documents');
            },
            onError: (error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        setErrorMessage(error.response.status + ': Validation error: could not process');
                    } else {
                        setErrorMessage(error.response.status + ': ' + error.response.data.message);
                    }
                } else if (error.request) {
                    setErrorMessage('Server not reachable');
                    console.log(error.request);
                } else {
                    setErrorMessage('Undefined error, please contact your admin');
                    console.log('Error', error.message);
                }
                setTimeout(function () {
                    setErrorMessage('');
                }, 10000);
            }
        }
    );

    const moveToInboxMutation = useMutation(
        ({documentID, num_positions_assigned}) => {
            const newState = num_positions_assigned > 0 ? 2 : 1;
            return DocumentService.patch(documentID, { state: newState });
        },
        {
            onSuccess: () => {
                // TODO: use result of mutation directly
                queryClient.invalidateQueries('documents');
            },
            onError: (error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        setErrorMessage(error.response.status + ': Validation error: could not process');
                    } else {
                        setErrorMessage(error.response.status + ': ' + error.response.data.message);
                    }
                } else if (error.request) {
                    setErrorMessage('Server not reachable');
                    console.log(error.request);
                } else {
                    setErrorMessage('Undefined error, please contact your admin');
                    console.log('Error', error.message);
                }
                setTimeout(function () {
                    setErrorMessage('');
                }, 10000);
            }
        }
    );

    const deleteMutation = useMutation(
        (documentID) => {
            return DocumentService.delete(documentID);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries('documents');
            },
            onError: (error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        setErrorMessage(error.response.status + ': Validation error: could not process');
                    } else {
                        setErrorMessage(error.response.status + ': ' + error.response.data.message);
                    }
                } else if (error.request) {
                    setErrorMessage('Server not reachable');
                    console.log(error.request);
                } else {
                    setErrorMessage('Undefined error, please contact your admin');
                    console.log('Error', error.message);
                }
                setTimeout(function () {
                    setErrorMessage('');
                }, 10000);
            },
            onSettled: () => {
                setEntityToDelete(null);
                setInTransaction(false);
            }
        }
    );

    const AVAILABLE_ACTIONS = {
        delete: {
            // eslint-disable-next-line react/display-name
            Cell: (row) => {
                return (
                    <Button
                        color="error"
                        size="small"
                        startIcon={<DeleteIcon fontSize="small" />}
                        onClick={() => setEntityToDelete(row.original)}>
                        Löschen
                    </Button>
                );
            },
            tooltip: 'löschen'
        },
        archive: {
            // eslint-disable-next-line react/display-name
            Cell: (row) => {
                return (
                    <Button
                        startIcon={<ArchiveOutlined />}
                        size="small"
                        onClick={() => archiveMutation.mutate(row.original.id)}>
                        Archivieren
                    </Button>
                );
            },
            tooltip: 'archivieren'
        },
        unarchive: {
            // eslint-disable-next-line react/display-name
            Cell: (row) => {
                if (row.original.last_state !== undefined && row.original.last_state !== null && row.original.last_state > 0) {
                    return (
                        <Button
                            startIcon={<OutboxOutlined />}
                            size="small"
                            onClick={() => unarchiveMutation.mutate({ documentID: row.original.id, last_state: row.original.last_state })}>
                            Wiederherstellen
                        </Button>
                    );
                }

                return null;
            },
            tooltip: 'wiederherstellen'
        },
        moveToInbox: {
            // eslint-disable-next-line react/display-name
            Cell: (row) => {
                return (
                    <Button
                        startIcon={<InboxOutlined />}
                        size="small"
                        onClick={() => moveToInboxMutation.mutate({ documentID: row.original.id, num_positions_assigned: row.original.num_positions_assigned })}>
                        Eingangsbereich
                    </Button>
                );
            },
            tooltip: 'in Eingangsbereich verschieben'
        },
        copy: {
            // eslint-disable-next-line react/display-name
            Cell: (row) => <CopyAngeboteDialog documentID={row.original.id} />,
            tooltip: 'kopieren'
        }
    };

    const navigate = useNavigate();
    const pageRoot = type === 'angebote' || type === 'archive' ? '/angebote/' : '/eingang/';

    if (isError) {
        setErrorMessage(error.response.status + ': ' + error.response.data.message);
    }

    const columns = useMemo(
        () =>
            [
                {
                    id: 'name',
                    accessorFn: (row) => row.meta?.meta?.project_name || 'Unbenanntes Projekt',
                    header: 'Name',
                    maxSize: 250,
                    // eslint-disable-next-line react/display-name
                    Cell: ({ cell }) => (
                        <Tooltip title={cell.row.original.meta?.meta?.project_name} placement="bottom">
                            <span>{cell.row.original.meta?.meta?.project_name || 'Unbenanntes Projekt'}</span>
                        </Tooltip>
                    ),
                    muiTableBodyCellProps: {
                        sx: {
                            fontWeight: '500'
                        }
                    }
                },
                {
                    id: 'internal-id',
                    accessorFn: (row) => row.meta?.meta?.ft_internal_nr || '-',
                    header: 'FT-Nr.',
                    maxSize: 50,
                    // eslint-disable-next-line react/display-name
                    Cell: ({ cell }) => (
                        <Tooltip title={cell.row.original.meta?.meta?.ft_internal_nr} placement="bottom">
                            <span>{cell.row.original.meta?.meta?.ft_internal_nr || '-'}</span>
                        </Tooltip>
                    ),
                    muiTableBodyCellProps: {
                        sx: {
                            fontWeight: '500'
                        }
                    }
                },
                {
                    accessorKey: 'client',
                    header: 'Kunde',
                    // eslint-disable-next-line react/display-name
                    Cell: ({ cell }) => (
                        <Tooltip title={cell.row.original?.client?.name || '-'} placement="bottom">
                            <span>{cell.row.original?.client?.name || '-'}</span>
                        </Tooltip>
                    ),
                    size: 75
                },
                {
                    accessorKey: 'user.name',
                    header: 'Bearbeiter',
                    size: 50,
                    // eslint-disable-next-line react/display-name
                    Cell: ({ cell }) => (
                        <Tooltip title={cell.row.original.user?.public_name} placement="bottom">
                            <span>{cell.row.original.user?.public_name || cell.row.original.user?.name || '-'}</span>
                        </Tooltip>
                    )
                },
                // {
                //     accessorKey: 'total_price',
                //     id: 'volume',
                //     header: 'Volumen',
                //     enableColumnFilter: false,
                //     enableSorting: false,
                //     size: 50,
                //     // eslint-disable-next-line react/display-name
                //     Cell: ({ cell }) => (
                //         <span>
                //             {cell.getValue() === 0 ? '-' : String(cell.getValue().toFixed(2)).replace('.', ',') + '€'}
                //         </span>
                //     ),
                //     muiTableBodyCellProps: {
                //         align: 'right'
                //     }
                // },
                {
                    accessorKey: 'created_at',
                    header: 'Erstellt am',
                    // eslint-disable-next-line react/display-name
                    Cell: ({ cell }) => <span>{displayDate(cell.getValue())}</span>,
                    size: 50,
                    enableColumnFilter: false,
                    muiTableBodyCellProps: {
                        align: 'right'
                    }
                },
                {
                    id: 'deadline',
                    accessorFn: (row) => row.meta?.meta?.deadline,
                    header: 'Abgabetermin',
                    // eslint-disable-next-line react/display-name
                    Cell: ({ cell }) => (
                        <span>
                            {cell.row.original.meta?.meta?.deadline
                                ? displayDate(cell.row.original.meta.meta.deadline)
                                : ''}
                        </span>
                    ),
                    size: 50,
                    enableColumnFilter: false,
                    muiTableBodyCellProps: {
                        align: 'right'
                    }
                },
                {
                    accessorKey: 'num_positions',
                    header: '#Positionen',
                    size: 50,
                    enableColumnFilter: false,
                    // eslint-disable-next-line react/display-name
                    Cell: ({ cell }) => <span>{cell.getValue()}</span>,
                    muiTableBodyCellProps: {
                        align: 'right'
                    }
                },
                {
                    accessorKey: 'num_requests',
                    header: 'Antworten',
                    enableColumnFilter: false,
                    enableSorting: false,
                    size: 25,
                    // eslint-disable-next-line react/display-name
                    Cell: ({ cell }) =>
                        cell.row.original.num_requests > 0 ? (
                            <Box>
                                <LinearProgress
                                    variant="determinate"
                                    value={
                                        ((cell.row.original.num_responses * 1.0) / cell.row.original.num_requests) * 100
                                    }
                                />
                                <Typography variant="body2" color="text.secondary">
                                    {cell.row.original.num_responses} von {cell.row.original.num_requests}
                                </Typography>
                            </Box>
                        ) : (
                            'Keine Anfragen gestellt'
                        ),
                    muiTableBodyCellProps: {
                        align: 'right'
                    }
                },
                {
                    accessorKey: 'state',
                    header: 'Status',
                    // eslint-disable-next-line react/display-name
                    Cell: ({ cell }) => <span>{DOCUMENT_STATUS[cell.getValue()]}</span>,
                    size: 50
                }
            ].filter((col) => {
                return (
                    (col.hasOwnProperty('accessorKey') && excludeCols.indexOf(col.accessorKey) === -1) ||
                    (!col.hasOwnProperty('accessorKey') && excludeCols.indexOf(col.id) === -1)
                );
            }),
        [excludeCols]
    );

    const columnOrder = [
        'mrt-row-expand',
        'internal-id',
        'name',
        'client',
        'user.name',
        'volume',
        'created_at',
        'deadline',
        'num_positions',
        'num_requests',
        'state',
        'mrt-row-actions'
    ].filter(
        (col) =>
            (col.hasOwnProperty('accessorKey') && excludeCols.indexOf(col.accessorKey) === -1) ||
            (!col.hasOwnProperty('accessorKey') && excludeCols.indexOf(col.id) === -1)
    );

    return (
        <PageLayout title={title}>
            <Box
                component="main"
                sx={{
                    flexGrow: 1
                }}>
                {entityToDelete && (
                    <Dialog open={entityToDelete !== null} onClose={() => setEntityToDelete(null)}>
                        <DialogTitle>LV löschen?</DialogTitle>
                        <DialogContent>
                            {`LV "${entityToDelete.name}" wird gelöscht`}
                            <DialogActions>
                                <Button
                                    startIcon={<CancelIcon />}
                                    variant="contained"
                                    color="error"
                                    size="small"
                                    disabled={inTransaction}
                                    onClick={() => setEntityToDelete(null)}>
                                    Abbrechen
                                </Button>
                                <Button
                                    startIcon={<DeleteIcon />}
                                    variant="contained"
                                    disabled={inTransaction}
                                    size="small"
                                    color="success"
                                    onClick={() => {
                                        setInTransaction(true);
                                        deleteMutation.mutate(entityToDelete.id);
                                    }}>
                                    Löschen
                                </Button>
                            </DialogActions>
                            {inTransaction ? (
                                <Grid container>
                                    <Grid alignItems="center" sx={{ display: 'flex', color: 'neutral.700' }}>
                                        <CircularLoading width="1.5rem" mr={1} /> <div>LV Löschen...</div>
                                    </Grid>
                                </Grid>
                            ) : null}
                        </DialogContent>
                    </Dialog>
                )}
                <Container maxWidth={false}>
                    {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                    {type === 'angebote' || type === 'archive' ? (
                        <Box sx={{ mb: 2, mt: 1 }}>
                            <Tabs
                                value={tabValue}
                                onChange={(event, newValue) => {
                                    setTabValue(newValue);
                                }}>
                                <Tab label="Angebote" href="/angebote" />
                                <Tab label="Archiv" href="/archiv" />
                            </Tabs>
                        </Box>
                    ) : null}
                    <Box sx={{ mt: 3 }}>
                        <MaterialReactTable
                            columns={columns}
                            data={data?.data.documents ?? []}
                            renderTopToolbarCustomActions={() => {
                                if (type === 'eingang') {
                                    return <ImportDialog />;
                                }
                            }}
                            initialState={{
                                showColumnFilters: false,
                                density: 'compact',
                                columnOrder: columnOrder
                            }}
                            muiTableBodyCellProps={({ cell }) => ({
                                onDoubleClick: () => {
                                    navigate(pageRoot + cell.row.original.id);
                                }
                            })}
                            renderDetailPanel={
                                type === 'angebote' || type === 'archive'
                                    ? // eslint-disable-next-line react/display-name
                                      (row) => (
                                          <RequestView requests={row.row.original.requests} invalidate={invalidate} />
                                      )
                                    : null
                            }
                            manualFiltering
                            manualPagination
                            manualSorting
                            muiToolbarAlertBannerProps={
                                isError
                                    ? {
                                          color: 'error',
                                          children: 'Error loading data'
                                      }
                                    : undefined
                            }
                            onColumnFiltersChange={setColumnFilters}
                            onGlobalFilterChange={setGlobalFilter}
                            onPaginationChange={setPagination}
                            onSortingChange={setSorting}
                            rowCount={data?.data?.pagination.num_total ?? 0}
                            state={{
                                columnFilters,
                                globalFilter,
                                isLoading,
                                pagination,
                                showAlertBanner: isError,
                                showProgressBars: isFetching,
                                sorting
                            }}
                            localization={MRT_Localization_DE}
                            muiTablePaperProps={{
                                elevation: 0,
                                sx: {
                                    backgroundColor: 'white'
                                }
                            }}
                            muiTableProps={{
                                sx: {
                                    tableLayout: 'fixed',
                                    backgroundClip: 'white'
                                }
                            }}
                            muiTopToolbarProps={{
                                sx: {
                                    backgroundColor: 'white'
                                }
                            }}
                            muiBottomToolbarProps={{
                                sx: {
                                    backgroundColor: 'white'
                                }
                            }}
                            muiTableHeadProps={{
                                sx: {
                                    background: 'white',
                                    '& tr': {
                                        backgroundColor: 'white',
                                        borderBottom: '1px solid black',
                                        borderColor: 'fassbender.green'
                                    },
                                    textTransform: 'none',
                                    fontWeight: 'bold'
                                }
                            }}
                            muiTableHeadCellProps={{
                                sx: {
                                    '& .Mui-TableHeadCell-Content': {
                                        justifyContent: 'space-between'
                                    },
                                    textTransform: 'none',
                                    fontWeight: 'bold'
                                }
                            }}
                            muiTableBodyProps={{
                                sx: () => ({
                                    '& tr': {
                                        borderBottom: '1px solid #e0e0e0',
                                        backgroundColor: 'white'
                                    }
                                })
                            }}
                            muiTableBodyRowProps={{
                                sx: () => ({
                                    '&:hover': {
                                        cursor: 'pointer'
                                    }
                                })
                            }}
                            positionActionsColumn="last"
                            displayColumnDefOptions={{
                                'mrt-row-actions': { size: 150 },
                                'mrt-row-expand': { size: 10, minSize: 5, maxSize: 10 }
                            }}
                            enableRowActions
                            renderRowActions={({ row }) => {
                                return (
                                    <>
                                        {actionsToDisplay.map((action) => (
                                            <Tooltip
                                                title={
                                                    row.original.meta?.meta?.project_name +
                                                    ' ' +
                                                    AVAILABLE_ACTIONS[action].tooltip
                                                }
                                                key={action}>
                                                <span>{AVAILABLE_ACTIONS[action].Cell(row)}</span>
                                            </Tooltip>
                                        ))}
                                        {row.original.save_paths === null ? null : (
                                            <Tooltip title={'Downloads'}>
                                                <span>
                                                    <DownloadPopover offerFiles={row.original.save_paths} />
                                                </span>
                                            </Tooltip>
                                        )}
                                    </>
                                );
                            }}
                        />
                    </Box>
                </Container>
            </Box>
        </PageLayout>
    );
}
