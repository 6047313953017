import { ExpandLess, ExpandMore } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import { default as CheckCircleOutlineIcon, default as DoneIcon } from '@mui/icons-material/CheckCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import GavelIcon from '@mui/icons-material/Gavel';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import SaveIcon from '@mui/icons-material/Save';
import {
    Alert,
    Box,
    Button,
    ButtonGroup,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    CircularProgress,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fab,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { DOCUMENT_STATUS } from '../pages/DocumentTablePage';
import { checkUnsettledChildren, recursivelyFindChildren } from '../utils/HierarchyUtils';
import { CircularLoading } from './CircularLoading.js';
// import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import { alpha, useTheme } from '@mui/material/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import 'moment/locale/de';
import DocumentService from '../api/services/Document.js';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useEffect, useRef, useState } from 'react';

import moment from 'moment';
import { useMutation, useQueryClient } from 'react-query';

const AVAILABBLE_FORMATS = {
    excel: 'Excel',
    pdf: 'PDF',
    gaeb: 'GAEB'
};

const EDIT_FIELDS = {
    project_name: {
        label: 'Objektbezeichnung',
        accessorKey: 'project_name',
        location: 'object'
    },
    planner: {
        label: 'Projektentwickler',
        accessorKey: 'planner',
        location: 'object'
    },
    investor: {
        label: 'Bauherr/Investor',
        accessorKey: 'investor',
        location: 'object'
    },
    deadline: {
        label: 'Abgabetermin',
        accessorKey: 'deadline',
        type: 'datetime',
        location: 'lv'
    },
    execution_start_time: {
        label: 'Ausführungszeitraum Beginn',
        accessorKey: 'execution_start_time',
        type: 'datetime',
        location: 'object'
    },
    execution_end_time: {
        label: 'Ausführungszeitraum Ende',
        accessorKey: 'execution_end_time',
        type: 'datetime',
        location: 'object'
    },
    award_date: {
        label: 'Vergabetermin',
        accessorKey: 'award_date',
        type: 'datetime',
        location: 'lv'
    },
    project_status: {
        label: 'Projektstand',
        accessorKey: 'project_status',
        location: 'object'
    },
    consultant: {
        label: 'Projektberater',
        accessorKey: 'consultant',
        location: 'object'
    },
    ft_internal_nr: {
        label: 'Interne Nr.',
        accessorKey: 'ft_internal_nr',
        location: 'object'
    },
    client_contact: {
        label: 'Ansprechpartner Kunde',
        accessorKey: 'client_contact',
        location: 'lv'
    },
    client_contact_title: {
        label: 'Titel Ansprechpartner Kunde (z.B. Herr / Frau)',
        accessorKey: 'client_contact_title',
        location: 'lv'
    }
};

const PriceContainer = ({ minPrice, totalPrice, maxPrice }) => {
    const theme = useTheme();

    const diff = maxPrice - minPrice;

    return (
        <div style={{ display: 'flex', alignItems: 'center', margin: '25px 0 35px' }}>
            <div style={{ position: 'relative', width: '100%' }}>
                <div
                    style={{
                        position: 'absolute',
                        left: '10%',
                        bottom: 0,
                        height: 1,
                        width: '80%',
                        backgroundColor: theme.palette.primary.main,
                        boxShadow: `0 0 5px 0 ${alpha(theme.palette.primary.main, 0.5)}`
                    }}
                />
                <div
                    style={{
                        position: 'absolute',
                        left: '10%',
                        top: 'calc(50% - 6px)',
                        height: 10,
                        width: 10,
                        borderRadius: '50%',
                        backgroundColor: theme.palette.primary.main,
                        border: `1px solid ${theme.palette.fassbender.green}`
                    }}>
                    <div
                        style={{
                            textAlign: 'center',
                            fontSize: '12px',
                            whiteSpace: 'nowrap',
                            marginLeft: '-100%',
                            marginRight: '-100%',
                            marginTop: '25px'
                        }}>
                        {String(Math.trunc(minPrice))}€
                    </div>
                </div>
                <div
                    style={{
                        position: 'absolute',
                        left: '90%',
                        top: 'calc(50% - 6px)',
                        height: 10,
                        width: 10,
                        borderRadius: '50%',
                        backgroundColor: theme.palette.primary.main,
                        border: `1px solid ${theme.palette.fassbender.green}`
                    }}>
                    {' '}
                    <div
                        style={{
                            textAlign: 'center',
                            fontSize: '12px',
                            whiteSpace: 'nowrap',
                            marginLeft: '-100%',
                            marginRight: '-100%',
                            marginTop: '25px'
                        }}>
                        {String(Math.trunc(maxPrice))}€
                    </div>
                </div>
                <div
                    style={{
                        position: 'absolute',
                        left: `calc((${totalPrice} - ${minPrice}) / ${diff} * 80% + 10%)`,
                        top: 'calc(50% - 9px)',
                        height: 16,
                        width: 16,
                        borderRadius: '50%',
                        backgroundColor: theme.palette.primary.main,
                        border: `1px solid ${theme.palette.fassbender.green}`
                    }}>
                    <div
                        style={{
                            textAlign: 'center',
                            fontSize: '12px',
                            whiteSpace: 'nowrap',
                            marginLeft: '-100%',
                            marginRight: '-100%',
                            marginTop: '28px',
                            fontWeight: 'bold',
                            background: theme.palette.neutral[100],
                            borderBottom: `1px solid ${theme.palette.fassbender.red}`
                        }}>
                        {String(Math.trunc(totalPrice))}€
                    </div>
                </div>
            </div>
        </div>
    );
};

export function DownloadFiles({ offerFiles }) {
    return (
        <div>
            {Object.keys(offerFiles).map((fileType) => {
                return (
                    <a
                        key={fileType}
                        style={{ textDecoration: 'none' }}
                        href={`/api/files/${offerFiles[fileType]}`}
                        download>
                        <Button
                            variant="contained"
                            size="small"
                            color="info"
                            startIcon={<FileDownloadIcon fontSize="small" />}
                            sx={{ marginRight: '5px' }}>
                            {fileType.toUpperCase()}
                        </Button>
                    </a>
                );
            })}
        </div>
    );
}

function HierarchicalList(props) {
    let {
        hierarchy,
        hierarchiesToDisplay,
        setHierarchiesToDisplay,
        hierarchyTitles,
        level,
        hierarchiesSubOpen,
        setHierarchiesSubOpen
    } = props;

    const hierarchiesClicked = function (hierarchyID) {
        let updateObj = {};
        updateObj[hierarchyID] = !hierarchiesSubOpen[hierarchyID];
        setHierarchiesSubOpen({ ...hierarchiesSubOpen, ...updateObj });
    };

    const hierarchyDisplayUpdateHandler = function (hierarchy) {
        let updateObj = {};
        updateObj[hierarchy.id] = !hierarchiesToDisplay[hierarchy.id];
        const childrenToUpdate = recursivelyFindChildren(hierarchy);
        childrenToUpdate.forEach((childID) => {
            updateObj[childID] = !hierarchiesToDisplay[hierarchy.id];
        });
        setHierarchiesToDisplay({ ...hierarchiesToDisplay, ...updateObj });
    };

    return (
        <List sx={{ maxWidth: 500, pl: level * 7.5 }} disablePadding>
            {Object.keys(hierarchy)
                .sort((a, b) => parseInt(a) - parseInt(b))
                .map((oz_part, idx) => (
                    <div key={hierarchy[oz_part].id}>
                        <ListItem
                            disablePadding
                            secondaryAction={
                                hierarchy[oz_part].hasOwnProperty('children') &&
                                Object.keys(hierarchy[oz_part].children).length > 0 ? (
                                    <IconButton
                                        edge="end"
                                        onClick={() => hierarchiesClicked(hierarchy[oz_part].id)}
                                        checked={hierarchiesSubOpen[hierarchy[oz_part].id]}>
                                        {hierarchiesSubOpen[hierarchy[oz_part].id] ? <ExpandLess /> : <ExpandMore />}
                                    </IconButton>
                                ) : null
                            }>
                            <ListItemButton
                                role={undefined}
                                dense
                                onClick={() => hierarchyDisplayUpdateHandler(hierarchy[oz_part])}>
                                <ListItemIcon>
                                    <Checkbox
                                        size="small"
                                        edge="start"
                                        checked={
                                            hierarchiesToDisplay[hierarchy[oz_part].id] ||
                                            hierarchiesToDisplay[hierarchy[oz_part].id] === undefined
                                        }
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': 'label' + idx }}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        hierarchy[oz_part].id +
                                        '. ' +
                                        (hierarchyTitles.hasOwnProperty(hierarchy[oz_part].id)
                                            ? hierarchyTitles[hierarchy[oz_part].id].heading
                                            : '')
                                    }
                                    id={'label' + idx}
                                />
                            </ListItemButton>
                        </ListItem>
                        {hierarchy[oz_part].hasOwnProperty('children') &&
                        Object.keys(hierarchy[oz_part].children).length > 0 ? (
                            <Collapse in={hierarchiesSubOpen[hierarchy[oz_part].id]} timeout="auto" unmountOnExit>
                                <HierarchicalList
                                    hierarchy={hierarchy[oz_part].children}
                                    setHierarchiesToDisplay={setHierarchiesToDisplay}
                                    setHierarchiesSubOpen={setHierarchiesSubOpen}
                                    hierarchiesToDisplay={hierarchiesToDisplay}
                                    hierarchiesSubOpen={hierarchiesSubOpen}
                                    hierarchyTitles={hierarchyTitles}
                                    level={level + 1}
                                />
                            </Collapse>
                        ) : null}
                    </div>
                ))}
        </List>
    );
}

const displayEditFields = function (
    fieldLocation,
    metaFieldEditing,
    setMetaFieldEditing,
    metaFieldValues,
    setMetaFieldValues,
    metaInputRef,
    handleMetaFieldSave
) {
    return Object.entries(EDIT_FIELDS)
        .filter(([key]) => EDIT_FIELDS[key].location === fieldLocation)
        .map(([key, value]) => {
            return (
                <Grid item xs={3} key={key}>
                    <Box
                        sx={{
                            display: 'flex',
                            backgroundColor: 'neutral.200',
                            p: 1,
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}>
                        <Typography sx={{ fontWeight: 'bold' }}>{value.label}:</Typography>
                        {metaFieldEditing[key] ? (
                            <Tooltip title={value.label + ' speichern'}>
                                <IconButton
                                    color="primary"
                                    sx={{ m: 0, p: 0, justifyContent: 'right' }}
                                    onClick={() => handleMetaFieldSave(key)}>
                                    <SaveIcon />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <Tooltip title={value.label + ' bearbeiten'}>
                                <IconButton
                                    color="primary"
                                    sx={{ m: 0, p: 0, justifyContent: 'right' }}
                                    onClick={() => {
                                        setMetaFieldEditing({
                                            ...metaFieldEditing,
                                            [key]: true
                                        });
                                    }}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Box>
                    <Box
                        sx={{
                            p: 1,
                            border: '1px solid',
                            borderColor: 'neutral.200',
                            borderBottomLeftRadius: 1,
                            borderBottomRightRadius: 1
                        }}>
                        {metaFieldEditing[key] ? (
                            EDIT_FIELDS[key].type == 'datetime' ? (
                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'de'}>
                                    <DesktopDatePicker
                                        inputFormat="DD.MM.YYYY"
                                        inputRef={metaInputRef[key]}
                                        label={value.label}
                                        value={metaFieldValues[key]}
                                        onChange={(newValue) => {
                                            setMetaFieldValues({
                                                ...metaFieldValues,
                                                [key]: newValue
                                            });
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            ) : (
                                <TextField
                                    multiline
                                    defaultValue={metaFieldValues[key]}
                                    onChange={(e) =>
                                        setMetaFieldValues({
                                            ...metaFieldValues,
                                            [key]: e.target.value
                                        })
                                    }
                                    inputRef={metaInputRef[key]}
                                />
                            )
                        ) : EDIT_FIELDS[key].type == 'datetime' && metaFieldValues[key] !== '' ? (
                            <Typography>{moment(metaFieldValues[key]).format('DD.MM.YYYY')}</Typography>
                        ) : (
                            <Typography sx={{ whiteSpace: 'pre-line' }}>{metaFieldValues[key]}</Typography>
                        )}
                    </Box>
                </Grid>
            );
        });
};

export default function DocumentMeta(props) {
    const [hierarchiesSubOpen, setHierarchiesSubOpen] = useState({});
    const [showTransitionModal, setShowTransitionModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [numPositions, setNumPositions] = useState('');
    const [numPositionsAssigned, setNumPositionsAssigned] = useState('');
    const [requestsToBeSent, setRequestsToBeSent] = useState([]);
    const [inTransaction, setInTransaction] = useState(false);
    const [longText, setLongText] = useState(false);

    const [metaFieldEditing, setMetaFieldEditing] = useState(
        Object.keys(EDIT_FIELDS).reduce((acc, key) => {
            acc[key] = false;
            return acc;
        }, {})
    );

    const [metaFieldValues, setMetaFieldValues] = useState(
        Object.keys(EDIT_FIELDS).reduce((acc, key) => {
            if (props.meta.hasOwnProperty('meta') && props.meta.meta.hasOwnProperty(EDIT_FIELDS[key].accessorKey)) {
                acc[key] = props.meta.meta[EDIT_FIELDS[key].accessorKey];
            } else {
                acc[key] = '';
            }
            return acc;
        }, {})
    );

    const metaInputRef = Object.keys(EDIT_FIELDS).reduce((acc, key) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        acc[key] = useRef(null);
        return acc;
    }, {});

    let defaultOfferValidity = null;
    let defaultPriceValidity = null;
    if (props.offerValidUntil) {
        defaultOfferValidity = new Date(props.offerValidUntil + 'Z');
    } else {
        defaultOfferValidity = new Date();
        defaultOfferValidity.setHours(0);
        defaultOfferValidity.setMinutes(0);
        defaultOfferValidity.setSeconds(0);
        defaultOfferValidity.setMilliseconds(0);
        defaultOfferValidity.setDate(defaultOfferValidity.getDate() + 14); // 14 days
    }
    if (props.priceValidUntil) {
        defaultPriceValidity = new Date(props.priceValidUntil + 'Z');
    } else {
        defaultPriceValidity = new Date();
        defaultPriceValidity.setHours(0);
        defaultPriceValidity.setMinutes(0);
        defaultPriceValidity.setSeconds(0);
        defaultPriceValidity.setMilliseconds(0);
        defaultPriceValidity.setMonth(defaultPriceValidity.getMonth() + 6); // 14 days
    }

    let initFormatsToExport = {};
    Object.keys(AVAILABBLE_FORMATS).forEach((format) => {
        initFormatsToExport[format] = false;
    });
    const [formatsToExport, setFormatsToExport] = useState(initFormatsToExport);

    const [offerValidUntil, setOfferValidUntil] = useState(defaultOfferValidity);
    const [priceValidUntil, setPriceValidUntil] = useState(defaultPriceValidity);
    const [includeClientData, setIncludeClientData] = useState(false);

    const queryClient = useQueryClient();

    const handleMetaFieldSave = (fieldKey) => {
        metaUpdateMutation.mutate({ documentID: props.id, field: fieldKey, value: metaFieldValues[fieldKey] });
    };

    useEffect(() => {
        Object.keys(metaFieldEditing).forEach((fieldKey) => {
            if (metaFieldEditing[fieldKey] !== false) {
                metaInputRef[fieldKey].current.focus();
            }
        });
    }, [metaFieldEditing]);

    const metaUpdateMutation = useMutation({
        mutationFn: ({ documentID, field, value }) => {
            let updateData = {};
            updateData[field] = value;
            return DocumentService.patch(documentID, { metadata: updateData });
        },
        onError: (error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    setErrorMessage(error.response.status + ': Validation error: could not process');
                } else {
                    setErrorMessage(error.response.status + ': ' + error.response.data.message);
                }
            } else if (error.request) {
                setErrorMessage('Server not reachable');
                console.log(error.request);
            } else {
                setErrorMessage('Undefined error, please contact your admin');
                console.log('Error', error.message);
            }
            setTimeout(function () {
                setErrorMessage('');
            }, 10000);
        },
        onSettled: (data_, error_, variables) => {
            setMetaFieldEditing({ ...metaFieldEditing, [variables.field]: false });
        }
    });

    const review_finished_mutation = useMutation({
        mutationFn: ({ documentID, documentState, includeClientData }) => {
            return DocumentService.patch(documentID, { state: documentState, include_client_data: includeClientData });
        },
        onSuccess: (data, variables) => {
            if (variables.documentState === 1) {
                setNumPositions(data.data.num_positions);
                setNumPositionsAssigned(data.data.num_positions_assigned);
                setRequestsToBeSent(data.data.requests);
                setShowTransitionModal(true);
            } else {
                setShowTransitionModal(false);
            }
            queryClient.invalidateQueries(['documentData', variables.documentID]);
        },
        onError: (error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    setErrorMessage(error.response.status + ': Validation error: could not process');
                } else {
                    setErrorMessage(error.response.status + ': ' + error.response.data.message);
                }
            } else if (error.request) {
                setErrorMessage('Server not reachable');
                console.log(error.request);
            } else {
                setErrorMessage('Undefined error, please contact your admin');
                console.log('Error', error.message);
            }
            setTimeout(function () {
                setErrorMessage('Fehler: ' + error);
            }, 10000);
        },
        onSettled: () => {
            setInTransaction(false);
        }
    });

    const offer_finished_mutation = useMutation({
        mutationFn: ({ documentID, documentState, offerValidUntil, priceValidUntil, longText }) => {
            return DocumentService.patch(documentID, {
                state: documentState,
                offerValidUntil,
                priceValidUntil,
                longText,
                formatsToExport
            });
        },
        onSuccess: (data, variables) => {
            setShowTransitionModal(false);
            queryClient.invalidateQueries(['documentData', variables.documentID]);
        },
        onError: (error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    setErrorMessage(error.response.status + ': Validation error: could not process');
                } else {
                    setErrorMessage(error.response.status + ': ' + error.response.data.message);
                }
            } else if (error.request) {
                setErrorMessage('Server not reachable');
                console.log(error.request);
            } else {
                setErrorMessage('Undefined error, please contact your admin');
                console.log('Error', error.message);
            }
            setTimeout(function () {
                setErrorMessage('Fehler: ' + error);
            }, 10000);
        },
        onSettled: () => {
            setInTransaction(false);
        }
    });

    const offer_export_mutation = useMutation({
        mutationFn: ({ documentID }) => {
            return DocumentService.patch(documentID, {
                formatsToExportOnly: {...formatsToExport, 'excel': true}
            });
        },
        onSuccess: (data, variables) => {
            setShowTransitionModal(false);
            queryClient.invalidateQueries(['documentData', variables.documentID]);
        },
        onError: (error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    setErrorMessage(error.response.status + ': Validation error: could not process');
                } else {
                    setErrorMessage(error.response.status + ': ' + error.response.data.message);
                }
            } else if (error.request) {
                setErrorMessage('Server not reachable');
                console.log(error.request);
            } else {
                setErrorMessage('Undefined error, please contact your admin');
                console.log('Error', error.message);
            }
            setTimeout(function () {
                setErrorMessage('Fehler: ' + error);
            }, 10000);
        },
        onSettled: () => {
            setInTransaction(false);
        }
    });

    let validationMessage = '';
    let unsettledPositions = checkUnsettledChildren(props.positions);
    if (unsettledPositions) {
        validationMessage = 'Es fehlen zugeordnete Kontakte bei Positionen des Typs "individuelle Anfrage"';
    }

    return (
        <Box
            sx={{
                width: 'auto',
                height: 'auto',
                ml: 3,
                mr: 3
            }}>
            {props.state > 2 &&
                (props.editMode ? (
                    <Fab
                        variant="extended"
                        color="primary"
                        aria-label="edit"
                        onClick={() => {
                            props.setEditMode(false);
                        }}
                        sx={{ position: 'fixed', bottom: 16, right: 16 }}>
                        <DoneIcon sx={{ mr: 1 }} />
                        Bearbeitung beenden
                    </Fab>
                ) : (
                    <Fab
                        variant="extended"
                        color="primary"
                        aria-label="edit"
                        onClick={() => {
                            props.setEditMode(true);
                        }}
                        sx={{ position: 'fixed', bottom: 16, right: 16 }}>
                        <EditIcon sx={{ mr: 1 }} />
                        Positionen Bearbeiten
                    </Fab>
                ))}
            <Dialog open={showTransitionModal} onClose={() => setShowTransitionModal(false)}>
                <DialogTitle>Bearbeitung des LV abschließen?</DialogTitle>
                <DialogContent>
                    {errorMessage ? (
                        <Alert severity="error" sx={{ mb: 1 }}>
                            {errorMessage}
                        </Alert>
                    ) : (
                        ''
                    )}
                    Möchtest du die Bearbeitung des LV abschließen? Das bedeutet:
                    <ul>
                        <li>Du kannst keine weiteren Positionen zuweisen</li>
                        {requestsToBeSent.length ? (
                            <li>
                                Es werden Anfragen an folgende E-Mail-Adressen gestellt:{' '}
                                {requestsToBeSent.map((request) => request.contact.email).join(', ')}
                            </li>
                        ) : null}
                        <li>
                            Du hast {numPositionsAssigned} von {numPositions} angefragten Positionen für das Angebot
                            ausgewählt
                        </li>
                    </ul>
                    {requestsToBeSent.length ? (
                        <Box>
                            <FormControl component="fieldset">
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={includeClientData}
                                                sx={{ p: 0, pl: 1 }}
                                                onChange={(e) => {
                                                    setIncludeClientData(e.target.checked);
                                                }}
                                                name="includeClientData"
                                            />
                                        }
                                        label="Der anfragende Bauunternehmer soll in der Anfrage genannt werden"
                                    />
                                </FormGroup>
                            </FormControl>
                        </Box>
                    ) : null}
                    <DialogActions>
                        <Button
                            startIcon={<CancelIcon />}
                            variant="contained"
                            color="error"
                            size="small"
                            disabled={inTransaction}
                            onClick={() => setShowTransitionModal(false)}>
                            Abbrechen
                        </Button>
                        <Button
                            startIcon={<GavelIcon />}
                            variant="contained"
                            disabled={inTransaction || props.documentLocked}
                            size="small"
                            color="success"
                            onClick={() => {
                                setInTransaction(true);
                                review_finished_mutation.mutate({
                                    documentID: props.id,
                                    documentState: 2,
                                    includeClientData
                                });
                            }}>
                            Bearbeitung abschließen
                        </Button>
                    </DialogActions>
                    {inTransaction || props.documentLocked ? (
                        <Grid container>
                            <Grid alignItems="center" sx={{ display: 'flex', color: 'neutral.700' }}>
                                <CircularLoading width="1.5rem" mr={1} /> <div>Bearbeitung abschließen...</div>
                            </Grid>
                        </Grid>
                    ) : null}
                </DialogContent>
            </Dialog>

            <Grid container spacing={1} columns={{ xs: 4, sm: 12 }}>
                <Grid item xs={4}>
                    <Card variant="outlined" sx={{ height: '100%' }}>
                        <CardHeader
                            title="LV-Daten"
                            sx={{ p: 2, backgroundColor: 'fassbender.green', color: 'white' }}
                        />
                        <CardContent sx={{ p: 2, backgroundColor: 'neutral.100' }}>
                            <Grid container spacing={2} columns={{ xs: 3 }}>
                                <Grid item xs={3}>
                                    <Typography sx={{ fontWeight: 'bold' }}>Bearbeiter:</Typography>
                                    <Typography>{props.user}</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography sx={{ fontWeight: 'bold' }}>Eingangsdatum:</Typography>
                                    <Typography>{new Date(props.created_at).toLocaleString('de-DE')}</Typography>
                                </Grid>
                                {displayEditFields(
                                    'lv',
                                    metaFieldEditing,
                                    setMetaFieldEditing,
                                    metaFieldValues,
                                    setMetaFieldValues,
                                    metaInputRef,
                                    handleMetaFieldSave
                                )}
                                <Grid item xs={3}>
                                    <Typography sx={{ fontWeight: 'bold' }}>Bauunternehmer:</Typography>
                                    <Typography>{props.client ? props.client.name : ''} </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                                        Volumen:
                                        {props.totalPrice ? (
                                            <Tooltip
                                                sx={{
                                                    color: 'neutral.400',
                                                    ml: 1
                                                }}
                                                placement="right-start"
                                                title={
                                                    'Derzeit angebotene Produkte ergeben ein Gesamtvolumen von ' +
                                                    String(props.totalPrice.toFixed(2)).replace('.', ',') +
                                                    '€. Bei Annahme sämtlicher Zusatzangebote und der jeweils ' +
                                                    'teuersten Alternativen, ist ein Gesamtvolumen von ' +
                                                    String(props.maxPrice.toFixed(2)).replace('.', ',') +
                                                    '€ möglich. Bei Auswahl der günstigsten Alternativen ist ein ' +
                                                    'Preis von ' +
                                                    String(props.minPrice.toFixed(2)).replace('.', ',') +
                                                    ' € zu erzielen. Eventualpositionen sind nur in der "teuersten" ' +
                                                    'Variante enthalten.'
                                                }>
                                                <InfoOutlinedIcon />
                                            </Tooltip>
                                        ) : null}
                                    </Typography>
                                    {props.totalPrice ? (
                                        <>
                                            <PriceContainer
                                                minPrice={props.minPrice}
                                                totalPrice={props.totalPrice}
                                                maxPrice={props.maxPrice}
                                            />
                                        </>
                                    ) : (
                                        <Typography color="text.secondary">Bisher kein Volumen ermittelbar</Typography>
                                    )}
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography sx={{ fontWeight: 'bold' }}>Status:</Typography>
                                    {props.requests.length ? (
                                        <Typography color="text.secondary" sx={{ mb: 1 }}>
                                            {props.requests.filter((req) => req.state > 0).length} von{' '}
                                            {props.requests.length} Anfragen verschickt
                                        </Typography>
                                    ) : null}
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {Object.keys(DOCUMENT_STATUS).map((statusLabel) => {
                                            const CircleComponent =
                                                props.state < statusLabel
                                                    ? RadioButtonUncheckedIcon
                                                    : props.state == statusLabel
                                                    ? PlayCircleOutlineIcon
                                                    : CheckCircleOutlineIcon;
                                            return (
                                                <div key={statusLabel}>
                                                    <Tooltip title={DOCUMENT_STATUS[statusLabel]}>
                                                        <CircleComponent
                                                            color={
                                                                props.state > statusLabel
                                                                    ? 'success'
                                                                    : props.state == statusLabel
                                                                    ? 'primary'
                                                                    : 'action'
                                                            }
                                                        />
                                                    </Tooltip>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </Grid>
                                {props.offerFiles && (
                                    <Grid item xs={3}>
                                        <Typography sx={{ fontWeight: 'bold' }}>Downloads:</Typography>
                                        <DownloadFiles offerFiles={props.offerFiles} />
                                    </Grid>
                                )}
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={8}>
                    <Card variant="outlined" sx={{ backgroundColor: 'neutral.100', height: '100%' }}>
                        <CardHeader title="Objektdaten" sx={{ p: 2, backgroundColor: 'neutral.700', color: 'white' }} />
                        <CardContent sx={{ p: 2 }}>
                            <Grid container spacing={1} columns={{ xs: 3, sm: 6, md: 9 }}>
                                {displayEditFields(
                                    'object',
                                    metaFieldEditing,
                                    setMetaFieldEditing,
                                    metaFieldValues,
                                    setMetaFieldValues,
                                    metaInputRef,
                                    handleMetaFieldSave
                                )}
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Grid
                container
                sx={{
                    mt: 2,
                    mb: 2,
                    p: 2,
                    border: '1px solid gray',
                    borderColor: 'neutral.200',
                    borderRadius: 1
                }}>
                <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ color: 'neutral.600', mr: 2 }} variant="h6">
                        Aktionen
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', gap: 20, marginTop: 10 }}>
                    {props.documentViewType === 'offers' ? (
                        <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'de'}>
                                <DesktopDatePicker
                                    label="Angebotsbindefrist"
                                    inputFormat="DD.MM.YYYY"
                                    disabled={props.state === 5}
                                    value={offerValidUntil}
                                    minDate={new Date()}
                                    onChange={(e) => {
                                        let formattedDate = e.toDate();
                                        formattedDate.setHours(0);
                                        formattedDate.setMinutes(0);
                                        formattedDate.setSeconds(0);
                                        formattedDate.setMilliseconds(0);
                                        setOfferValidUntil(formattedDate);
                                    }}
                                    renderInput={(params) => <TextField {...params} size="small" />}
                                />
                                <DesktopDatePicker
                                    label="Preisgültigkeit"
                                    inputFormat="DD.MM.YYYY"
                                    disabled={props.state === 5}
                                    value={priceValidUntil}
                                    minDate={new Date()}
                                    onChange={(e) => {
                                        let formattedDate = e.toDate();
                                        formattedDate.setHours(0);
                                        formattedDate.setMinutes(0);
                                        formattedDate.setSeconds(0);
                                        formattedDate.setMilliseconds(0);
                                        setPriceValidUntil(formattedDate);
                                    }}
                                    renderInput={(params) => <TextField {...params} size="small" />}
                                />
                            </LocalizationProvider>
                            <FormControl component="fieldset">
                                <FormLabel component="legend" sx={{ mb: 1 }}>
                                    Optionen
                                </FormLabel>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={longText}
                                                sx={{ p: 0, pl: 1 }}
                                                onChange={(e) => {
                                                    setLongText(e.target.checked);
                                                }}
                                                name="longText"
                                            />
                                        }
                                        label="Langtext-Version"
                                    />
                                </FormGroup>
                            </FormControl>
                            <FormControl component="fieldset">
                                <FormLabel component="legend" sx={{ mb: 1 }}>
                                    Export-Format(e) auswählen
                                </FormLabel>
                                <FormGroup>
                                    {Object.keys(AVAILABBLE_FORMATS).map((key) => {
                                        return (
                                            <FormControlLabel
                                                key={key}
                                                control={
                                                    <Checkbox
                                                        checked={formatsToExport[key]}
                                                        sx={{ p: 0, pl: 1 }}
                                                        onChange={(e) => {
                                                            setFormatsToExport({
                                                                ...formatsToExport,
                                                                [key]: e.target.checked
                                                            });
                                                        }}
                                                        name={key}
                                                    />
                                                }
                                                label={AVAILABBLE_FORMATS[key]}
                                            />
                                        );
                                    })}
                                </FormGroup>
                                <FormHelperText sx={{ p: 0, ml: 0 }}>
                                    Wähle aus, welche Export-Formate generiert werden sollen.
                                </FormHelperText>
                            </FormControl>
                            <ButtonGroup variant="outlined">
                                <Button
                                    variant="outlined"
                                    size="small"
                                    startIcon={<GavelIcon />}
                                    onClick={() => {
                                        setInTransaction(true);
                                        offer_finished_mutation.mutate({
                                            documentID: props.id,
                                            documentState: props.state,
                                            offerValidUntil,
                                            priceValidUntil,
                                            longText
                                        });
                                    }}
                                    disabled={inTransaction || showTransitionModal}>
                                    {inTransaction ? <CircularProgress size={20} sx={{ mr: 2 }} /> : null}{' '}
                                    {props.state <= 4 ? 'Angebot exportieren' : 'Angebot erneut exportieren'}
                                </Button>
                            </ButtonGroup>
                        </Box>
                    ) : (
                        <>
                            <Button
                                variant="contained"
                                size="small"
                                startIcon={<GavelIcon />}
                                onClick={() => {
                                    setInTransaction(true);
                                    review_finished_mutation.mutate({ documentID: props.id, documentState: 1 });
                                }}
                                disabled={
                                    props.state > 2 ||
                                    inTransaction ||
                                    showTransitionModal ||
                                    props.documentLocked ||
                                    unsettledPositions
                                }>
                                {inTransaction || props.documentLocked ? (
                                    <CircularProgress size={20} sx={{ mr: 2 }} />
                                ) : null}{' '}
                                {props.state <= 2 ? 'Bearbeitung Abschliesen' : 'Bearbeitung Abgeschlossen'}
                            </Button>
                            <Button
                                variant="outlined"
                                size="small"
                                startIcon={<GavelIcon />}
                                onClick={() => {
                                    setInTransaction(true);
                                    offer_export_mutation.mutate({
                                        documentID: props.id
                                    });
                                }}
                                disabled={inTransaction || showTransitionModal}>
                                {inTransaction ? <CircularProgress size={20} sx={{ mr: 2 }} /> : null}{' '}
                                Angebot in Excel exportieren
                            </Button>
                        </>
                    )}
                    {validationMessage ? <Alert severity="error">{validationMessage}</Alert> : ''}
                </Grid>
            </Grid>

            {props.documentViewType === 'documents' ? (
                <Card>
                    <CardHeader
                        title="Inhaltsverzeichnis"
                        variant="outlined"
                        sx={{ p: 2, backgroundColor: 'neutral.200' }}
                    />
                    <CardContent sx={{ p: 2, backgroundColor: 'neutral.100' }}>
                        <HierarchicalList
                            hierarchy={props.hierarchy.children}
                            setHierarchiesToDisplay={props.setHierarchiesToDisplay}
                            hierarchiesToDisplay={props.hierarchiesToDisplay}
                            setHierarchiesSubOpen={setHierarchiesSubOpen}
                            hierarchiesSubOpen={hierarchiesSubOpen}
                            hierarchyTitles={props.hierarchyTitles}
                            level={0}
                        />
                    </CardContent>
                </Card>
            ) : null}
        </Box>
    );
}
