import React from 'react';
import './App.css';
import AnswerPage from './pages/AnswerPage';
import DocumentPage from './pages/DocumentPage';
import LoginPage from './pages/LoginPage.js';
import DocumentTablePage from './pages/DocumentTablePage';
import UsersPage from './pages/UsersPage';
import UserEditPage from './pages/UserEditPage';
import ProvidersPage from './pages/ProvidersPage';
import ClientsPage from './pages/ClientsPage';
import { ThemeProvider } from '@mui/material/styles';
import AuthUtils from './api/auth/utils.js';
import { Routes, Route, Navigate } from 'react-router-dom';
import { theme } from './theme';

function PrivateRoute({ children }) {
    return AuthUtils.tokenIsValid() ? children : <Navigate to="/login" />;
}

export default function App() {
    return (
        <div className="App">
            <ThemeProvider theme={theme}>
                <Routes>
                    <Route path="/" element={<LoginPage />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route
                        path="/eingang"
                        element={
                            <PrivateRoute>
                                <DocumentTablePage
                                    typesToDisplay="1,2"
                                    actionsToDisplay={['delete']}
                                    excludeCols={['volume', 'num_requests', 'save_paths']}
                                    title="Eingang"
                                    type="eingang"
                                />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/eingang/:lvitem"
                        element={
                            <PrivateRoute>
                                <DocumentPage type="documents" />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/users"
                        element={
                            <PrivateRoute>
                                <UsersPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/users/:userID"
                        element={
                            <PrivateRoute>
                                <UserEditPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        exact
                        path="/angebote"
                        element={
                            <PrivateRoute>
                                <DocumentTablePage
                                    typesToDisplay="3,4,5"
                                    actionsToDisplay={['copy', 'archive', 'moveToInbox']}
                                    excludeCols={['num_positions']}
                                    title="Angebote"
                                    type="angebote"
                                />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        exact
                        path="/archiv"
                        element={
                            <PrivateRoute>
                                <DocumentTablePage
                                    typesToDisplay="6"
                                    actionsToDisplay={['delete', 'unarchive']}
                                    excludeCols={['num_positions']}
                                    title="Archiv"
                                    type="archive"
                                />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        exact
                        path="/angebote/:lvitem"
                        element={
                            <PrivateRoute>
                                <DocumentPage type="offers" />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        exact
                        path="/angebote/:document/:position"
                        element={
                            <PrivateRoute>
                                <AnswerPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        exact
                        path="/lieferanten"
                        element={
                            <PrivateRoute>
                                <ProvidersPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/bauunternehmer"
                        element={
                            <PrivateRoute>
                                <ClientsPage />
                            </PrivateRoute>
                        }
                    />
                    <Route path="*" element={<Navigate replace to="/eingang" />} />
                </Routes>
            </ThemeProvider>
        </div>
    );
}
